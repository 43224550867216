.App-header {
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 20px;
}

h1, h2 {
  color: #343a40;
}

p {
  color: #6c757d;
  word-break: break-word;
}

button {
  font-size: 1.25rem;
}

textarea, input {
  font-size: 1.15rem;
}

.card {
  border-radius: 10px;
  border: none;
}

.accordion-button:not(.collapsed) {
  background-color: #93c7ff;
  color: white;
}

.accordion-body {
  background-color: #f8f9fa;
}

span.maintext{
  word-break: break-word;
  flex-basis: auto;
}

span.subtext {
  word-break: break-word;
  color: #888;
  font-style: italic;
  margin-left: auto;
  margin-right: 5px;
}